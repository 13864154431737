import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "targetKompetensi";
const modelPath = "/programstudi/targetkompetensi/";

class TargetKompetensi extends ModelBase {
  constructor() {
    let model = {
        id: null,
        angkatan: null,
        kompetensi: null,
        target: null,
    };
    let requiredFields = [
      "angkatan", "kompetensi", "target"
    ];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default TargetKompetensi;